import cn from 'classnames';
import { WEBSHOP_STORES } from 'lib/shopify';
import Image from 'next/image';
import styles from './paymentIcons.module.scss';

const PaymentIcons = ({ className, locale, iconHeight = '4rem', iconWidth = '4.7rem' }) => (
  <div
    className={cn(styles.paymentIcons, className)}
    style={{
      '--iconHeight': iconHeight,
      '--iconWidth': iconWidth,
    }}
  >
    {locale === WEBSHOP_STORES.NL ? (
      <>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/ideal.svg" layout="fill" alt="Visa icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/mastercard.svg" layout="fill" alt="Visa icon" />
        </div>
        <div className={cn(styles.iconContainer, styles.withPadding)}>
          <Image priority src="/icons/visa.svg" layout="fill" alt="Visa icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/google_pay.svg" layout="fill" alt="Google pay icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/apple_pay.svg" layout="fill" alt="Apple pay icon" />
        </div>
        <div className={cn(styles.iconContainer, styles.withPadding)}>
          <Image priority src="/icons/shop_pay.svg" layout="fill" alt="Shop pay icon" />
        </div>
        <div className={cn(styles.iconContainer, styles.withPadding)}>
          <Image priority src="/icons/klarna2.svg" layout="fill" alt="Klarna pay icon" />
        </div>
      </>
    ) : null}

    {locale === WEBSHOP_STORES.FR ? (
      <>
        <div className={cn(styles.iconContainer, styles.withPadding)}>
          <Image priority src="/icons/visa.svg" layout="fill" alt="Visa icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/master-card.svg" layout="fill" alt="Master-card icon" />
        </div>
        <div className={cn(styles.iconContainer, styles.withPadding)}>
          <Image priority src="/icons/amex.svg" layout="fill" alt="Amex icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image
            priority
            src="/icons/cartes_bancaires.svg"
            layout="fill"
            alt="Cartes Bancaires icon"
          />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/apple_pay.svg" layout="fill" alt="Apple pay icon" />
        </div>
      </>
    ) : null}

    {locale === WEBSHOP_STORES.DE ? (
      <>
        <div className={cn(styles.iconContainer, styles.withPadding)}>
          <Image priority src="/icons/visa.svg" layout="fill" alt="Visa icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/master-card.svg" layout="fill" alt="Master-card icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/klarna.svg" layout="fill" alt="Klarna icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/paypal.svg" layout="fill" alt="Paypal icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/google_pay.svg" layout="fill" alt="Google pay icon" />
        </div>
        <div className={styles.iconContainer}>
          <Image priority src="/icons/apple_pay.svg" layout="fill" alt="Apple pay icon" />
        </div>
      </>
    ) : null}
  </div>
);

export default PaymentIcons;
