import classNames from 'classnames';
import Image from 'components/shared/image';
import { useAddItemsToCart, useUI } from 'hooks';
import { useEffect, useState, useRef } from 'react';
import SiteLink from 'components/shared/sitelink';
import { addLocaleToHref, formatPrice, reformatLocaleToContentfulLocale } from 'lib/utils/helpers';
import { useRouter } from 'next/router';
import handleDrag from 'lib/utils/handleDrag';
import useIsMobile from 'hooks/utils/useIsMobile';
import UnitPrice from 'components/shared/unit-price/unitPrice';
import { formatPriceByStoreLocale } from 'lib/shopify';

import styles from './freeItemPopup.module.scss';
import Icon from '../../shared/icon';

const FreeItemPopup = ({ items, className, globalModules, locale }) => {
  const { productpage, freeitem } = globalModules || {};
  const addItems = useAddItemsToCart();
  const [collapsed, setCollapsed] = useState(false);
  const { closeCartPopup } = useUI();
  const router = useRouter();
  const [contentfulData, setContentfulData] = useState([]);
  const isSlider = freeitem.isSlider.onOrOff || false;
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!items || !items.length) return;
    const fetchProducts = async IDs => {
      const products = await fetch('/api/contentful/get-products-by-ids', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          locale,
          productIds: IDs,
        }),
      });

      try {
        if (products.ok) {
          const productsWithContentfulData = await products.json();
          setContentfulData(productsWithContentfulData);
        }
        return null;
      } catch (e) {
        return products.status(500).json('There seems to have been an issue:', e);
      }
    };

    const IDs = items.map(item => item.freeProducts.map(({ id }) => id)).flat();

    if (IDs.length) {
      fetchProducts(IDs);
    }
  }, [items]);

  const handleAddToCart = async item => {
    addItems([
      {
        variantId: item.variantId,
        quantity: 1,
        customAttributes: [{ key: '_freeItem', value: 'true' }],
      },
    ]);
  };

  const FreeItem = ({ item }) => {
    const { title, featuredImage, handle } = item;
    const cData = contentfulData.find(({ slug }) => handle === slug);

    const size = cData?.size || '';
    const unitPrice = cData?.shopifyData.variants[0].unitPrice;
    const price = cData?.shopifyData.variants[0].price;
    const money = formatPriceByStoreLocale({
      locale,
      price: formatPrice(price),
    });

    const isLocaleAvailable = cData?.availableLocales?.includes(
      reformatLocaleToContentfulLocale(locale)
    );

    return (
      <div className={classNames({ [styles.item]: !isSlider, [styles.itemSlide]: isSlider })}>
        <SiteLink
          locale={locale}
          href={addLocaleToHref(handle, locale, router)}
          onClick={closeCartPopup}
          className={classNames(styles.itemImage, { [styles.clickDisabled]: !isLocaleAvailable })}
          draggable={false}
        >
          <Image src={featuredImage} layout="fill" draggable={false} />
        </SiteLink>{' '}
        <div className={styles.info}>
          <div className={styles.title}>{title}</div>
          <div className={styles.prices}>
            <span className={styles.price}>{money}</span>
            <span
              className={styles.freeText}
              style={{ color: freeitem?.priceFreeTextColor?.text || '' }}
            >
              {freeitem?.priceFreeText?.text || ''}
            </span>
          </div>
          <UnitPrice
            size={size}
            unitPrice={unitPrice}
            locale={locale}
            price={price}
            className={styles.unitPrice}
            globalModules={globalModules}
          />
        </div>
        <button
          type="button"
          className={styles.addToCartButton}
          onClick={() => handleAddToCart(item)}
        >
          {productpage?.addToCart?.text}
        </button>
      </div>
    );
  };

  const FreeItemsCarousel = ({ slides }) => {
    const carouselRef = useRef(null);
    const [dragging, setDragging] = useState(false);

    const dragHandler = e => {
      if (isMobile) return;

      setTimeout(() => setDragging(true), 200);

      const { scrollLeft } = carouselRef.current;
      handleDrag(e, carouselRef, {
        leftSideCallback: (ev, delta) => {
          carouselRef.current.scrollLeft = scrollLeft - delta.x;
        },
        rightSideCallback: (ev, delta) => {
          carouselRef.current.scrollLeft = scrollLeft - delta.x;
        },
      });
    };
    return (
      <div
        className={styles.slider}
        onPointerDown={dragHandler}
        ref={carouselRef}
        onPointerUp={() => {
          setDragging(false);
        }}
      >
        <div className={classNames(styles.itemsContainer, { [styles.clickDisabled]: dragging })}>
          {slides.map(item => (
            <FreeItem item={item} key={item.id} />
          ))}
        </div>
      </div>
    );
  };

  if (!items?.length) return null;

  return (
    <div className={classNames(styles.container, className, { [styles.collapsed]: collapsed })}>
      {collapsed && (
        <button
          type="button"
          className={styles.expandBtn}
          onClick={() => {
            setCollapsed(false);
          }}
          style={{
            backgroundColor: freeitem?.buttonColor?.text,
            color: freeitem?.buttonTextColor?.text,
          }}
        >
          {freeitem?.buttonText?.text}
        </button>
      )}
      {!collapsed && (
        <div className={styles.mainContent}>
          <button
            type="button"
            className={styles.closeBtn}
            onClick={() => {
              setCollapsed(true);
            }}
          >
            <Icon type="close" />
          </button>
          {!isSlider && (
            <>
              {items.map(itemGroup => (
                <div className={styles.itemGroup}>
                  <div className={styles.groupTitle}>
                    {freeitem?.discountTitle?.text
                      ? freeitem.discountTitle.text.replace('{count}', '')
                      : itemGroup.discountTitle}
                  </div>
                  {itemGroup.freeProducts.map(item => (
                    <FreeItem item={item} key={item.id} />
                  ))}
                </div>
              ))}
            </>
          )}
          {isSlider && (
            <>
              {items.map(itemGroup => (
                <div className={styles.itemGroup}>
                  <div className={styles.groupTitle}>
                    {freeitem?.discountTitle?.text
                      ? freeitem.discountTitle.text.replace('{count}', itemGroup.itemsLeft || '')
                      : itemGroup.discountTitle}
                  </div>
                  <FreeItemsCarousel slides={itemGroup.freeProducts} />
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FreeItemPopup;
